.main {
  color: var(--textColor);
}

.summaryHeader {
  background-color: rgba(15, 106, 160, 0.6);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 15px;
  }
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.section {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  div {
    margin-left: 15px;
  }
  svg {
    flex-shrink: 0;
  }
}
.section:nth-child(2) {
  padding-top: 30px;
  align-items: flex-start;
}

.section:last-child {
  border-bottom: none;
  display: block;
  div {
    margin-left: unset;
  }
}

.peopleSection {
  font-size: 15px;
  div:first-child {
    font-size: 17px;
    font-weight: bold;
  }
  div {
    margin-bottom: 10px;
    margin-left: 0 !important;
  }
  div:last-child {
    margin-bottom: unset;
  }
}

.totalPriceHeader {
  font-size: 30px;
  font-weight: bold;
}
.small {
  font-size: 15px;
  margin: 10px 0;
}
