:root {
  --disabledTile: #f0f0f0;
  --enabledTile: #79b2e2;
  --selectedTile: #136fa3;
  --blue1: #004e7a;
  --textColor: #363738;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.ctrs {
  //overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-position: left;
  color: white;
  font-family: "Inter Tight", sans-serif;
}
.stepOne {
  min-height: 850px;
  background-position: center;
}

.extraDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px !important;
  flex-wrap: wrap;
}

.buttonLike {
  width: 140px;
  color: var(--selectedTile);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  margin-right: 20px;
  height: 40px;
}

.ctrsContainer {
  max-width: 1408px;
  margin: auto;
  color: white;
  padding: 10px;
}

.dateNotification {
  position: absolute;
  left: 50%;
  top: 0;
  border-radius: 5px;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
  width: 500px;
  max-width: 100%;
  background-color: white;
  z-index: 1;
  color: #363738;
  font-size: 20px;
  padding: 20px;
  box-sizing: border-box;
  .description {
    margin-bottom: 40px;
  }

  .centered {
    text-align: center;
  }

  .animationWrapper {
    display: flex;
    justify-content: center;
    div {
      width: 100px;
      height: 100px;
    }
  }
}
.mainTitle {
  font-size: 45px;
  font-weight: bold;
  margin-top: 50px;
}
.secondTitle {
  font-size: 30px;
  font-weight: normal;
}
.mainContainer {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  position: relative;
}
.menuContainer {
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  justify-content: space-center;
  border-radius: 5px;
  flex-wrap: wrap;
  gap: 15px;
}
.menuContainerTop {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.2);
  gap: 20px;
}
.detailsView {
  display: flex;
  min-height: inherit;
}

.passengersWrapper {
  flex-grow: 1;
  padding: 50px 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  max-height: 800px;
  overflow: auto;
}

.passengerDetails {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  color: var(--textColor);
  margin-bottom: 20px;
}
.passengersMain {
  max-width: 1130px;
  height: fit-content;
  flex-grow: 1;
  position: relative;
}

.paymentButton {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 30px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: none;
    background-color: var(--selectedTile);
    color: #fff;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    padding: 0 30px;
    min-width: 430px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
  }
}

.paymentIcon {
  position: absolute;
  right: 30px;
  svg {
    vertical-align: bottom;
  }
}
.bigPadded {
  padding-left: 200px !important;
  padding-right: 200px !important;
}
.paymentWrapper {
  width: 700px;
  height: fit-content;
}
.rightBigPadded {
  padding-right: 100px;
}
.summaryWrapper {
  padding: 50px 20px 0 20px;
  min-height: inherit;
  border-left: 2px solid #dddddd;
  flex-basis: 500px;
}
.formHeader {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.costIndicatorMobile {
  display: none;
  justify-content: space-between;
  div {
    font-size: 14px;
    font-weight: bold;
  }
  span {
    font-size: 20px;
    font-weight: bold;
  }
}
.subNote {
  font-size: 15px;
  color: var(--textColor);
}

.secondMenuContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
  border-radius: 5px;
  position: relative;
}
.personPickerWrapper {
  background-color: var(--blue1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 740px;
  padding: 10px;
  height: 60px;
  flex-grow: 1;
}
.costIndicator {
  height: 60px;
  border-radius: 5px;
  background-color: #49a1bc;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  flex-grow: 1;
}
.proceed {
  height: 60px;
  border-radius: 5px;
  background-color: #1fc96f;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  width: 188px;
  flex-grow: 1;
  border: none;
  cursor: pointer;
}
.marginedTop {
  margin-top: 40px;
}
.waiting {
  cursor: wait !important;

  * {
    cursor: wait !important;
  }
}

.error {
  font-size: 25px;
  color: var(--textColor);
  padding: 50px;
}

.paymentHeader {
  font-size: 25px !important;
  height: 100px !important;
  span {
    margin-left: 25px !important;
  }
}
.paymentBody {
  border: 1px solid #dddddd;
  padding: 30px 100px;
  .radioLabel {
    color: var(--textColor);
    font-size: 23px;
    font-weight: bold;
    img {
      margin-left: 5px;
      position: relative;
      top: 4px;
    }
  }
  input[type="radio"] {
    appearance: none;

    border-radius: 50%;
    width: 35px;
    height: 35px;

    border: 1px solid var(--selectedTile);
    margin-right: 20px;
    background-clip: content-box;
    position: relative;
    top: 10px;
    padding: 3px;
  }
  input[type="radio"]:checked {
    background-color: var(--selectedTile);
  }
  input[type="checkbox"] {
    appearance: none;
    margin-right: 10px;
    width: 23px;
    height: 23px;
    border: 1px solid var(--selectedTile);
  }
  input[type="checkbox"]:checked {
    appearance: auto;

    // border: 2px solid var(--selectedTile);
  }
  .checkLabel {
    color: var(--textColor);
    font-size: 17px;
    a {
      text-decoration: none;
      color: #247eb1;
    }
  }
}
.consentWrapper {
  display: flex;
  align-items: center;
  margin-top: 35px;
}
.paymentNote {
  font-size: 15px;
  color: var(--textColor);
  margin-top: 20px;
  line-height: 200%;
}
.blocked {
  display: block;
  margin-top: 40px;
  button {
    width: 100%;
    border-radius: 5px;
    height: 70px;
    font-size: 23px;
  }
}
.backButton {
  background-color: #9c9ea1 !important;
  margin-top: 20px;

  .paymentIcon {
    right: unset !important;
    left: 30px;
  }
}

.selectLabel {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 15px 0;
}

.paymentError {
  margin-top: 20px;
  color: red;
}

.busyWrapper {
  display: flex;
  justify-content: center;
  min-height: 850px;
  align-items: center;
}
.failWrapper {
  color: var(--textColor);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 850px;
  padding: 30px;
  text-align: center;
}
.errorAnimation {
  width: 200px;
  height: 200px;
}
.successWrapper {
  padding: 50px;
  color: var(--textColor);
  display: flex;
  flex-wrap: wrap;
}
.successDetails {
  flex-basis: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.successHeader {
  font-size: 50px;
  font-weight: bold;
}
.reason {
  font-size: 30px;
  font-weight: normal;
  margin-top: 20px;
}
.failError {
  font-size: 30px;
  font-weight: bold;
  color: red;
  margin-top: 20px;
}
.failButton {
  background-color: var(--selectedTile);
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  padding: 5px 10px;
  margin-top: 20px;
  cursor: pointer;
  width: 270px;
  height: 80px;
}
.confirm {
  font-size: 30px;
  margin-top: 20px;
  font-weight: bold;
}
.limited {
  max-width: 357px;
}
.mailPrompt {
  font-size: 25px;
  font-weight: normal;
  margin-top: 20px;
}
.successSummary {
  div:first-child {
    max-width: 470px;
  }
  flex-basis: 35%;
}
.pickupSummary {
  margin-top: 10px;
  text-align: left;
  font-size: 16px;
  h3 {
    width: fit-content;
  }
  div {
    span:nth-child(2) {
      font-style: italic;
    }
  }
}
.mobileHelper {
  display: contents;
  .dropdownHelper {
    flex-basis: unset;
    max-width: 300px;
  }
}

.redirection {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px;
  color: var(--textColor);
  img {
    max-width: 100%;
  }
  .wrapper {
    max-width: 488px;
    text-align: center;
  }
  .redirectHeader {
    margin-top: 50px;
    font-size: 50px;
    font-weight: bold;
  }
  .redirectBody {
    margin-top: 30px;
    font-size: 25px;
  }
}
.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobilePayfooter {
  background-color: var(--selectedTile);
  color: #fff;
  padding: 5px 15px;
  font-size: 20px;
  font-weight: bold;
  height: 55px;
  display: none;
  justify-content: space-between;
  align-items: center;

  button {
    cursor: pointer;
    background-color: #fff;
    color: var(--blue1);
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
    height: 40px;
    padding: 10px 5px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span:first-child {
      margin-right: 7px;
    }
    svg {
      vertical-align: bottom;
    }
  }
}
.holderDropdownWrapper {
  .holderDropdown {
    width: unset !important;
  }
}

.extrasLabel {
  input {
    margin-right: 5px;
  }
}

@media screen and (max-width: 600px) {
  .noConstraint {
    min-width: unset !important;
  }
}
@media screen and (max-width: 1100px) {
  .right {
    right: 0;
    left: unset !important;
  }
  .mobilePayfooter {
    display: flex !important;
  }
  .ctrs:not(.stepOne) {
    background-image: none !important;
  }
  .react-calendar {
    width: unset !important;
  }
  .secondTitle {
    font-size: 18px;
  }
  .menuContainer,
  .mainContainer {
    padding: 10px;
  }
  .costIndicator {
    display: none !important;
  }
  .costIndicatorMobile {
    display: flex !important;
    flex-basis: 50%;
    max-width: calc(50% - 5px);
    margin-top: 10px;
    align-items: center;
  }
  .personPickerWrapper {
    display: contents !important;
  }
  .secondMenuContainer {
    background-color: var(--blue1);
    padding: 10px;
  }
  .proceed {
    max-width: 320px;
  }
  .proceedWrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .summaryWrapper {
    display: none;
  }
  .detailsView {
    display: block;
    padding-bottom: 20px;
  }
  .passengersWrapper {
    display: block;
    max-height: unset !important;
  }
  .menuContainerTop {
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1001;
    box-shadow: none;
    border-top: 1px solid #dddddd;
    padding: 10px 0 0 0 !important;
    .dropdownHelper {
      margin-top: 0 !important;
    }
  }
  .mobileHelper {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .buttonLike {
    background-color: transparent;
    color: var(--blue1);
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    width: 100%;
    padding: 0 !important;
    height: unset !important;
  }
  .bigPadded {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .rightBigPadded {
    padding: 20px !important;
    display: flex !important;
    justify-content: center !important;
    .paymentHeader {
      font-size: 20px !important;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      text-align: center;
      padding: 10px 40px 5px 40px;
      height: 70px !important;
      span {
        margin-left: 5px !important;
      }

      svg {
        margin-left: 15px;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
      }
    }
    .paymentBody {
      padding: 20px;
      .radioLabel {
        font-size: 15px;
      }
      input[type="radio"] {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
      .checkLabel {
        font-size: 14px;
      }
      .paymentButton {
        button {
          font-size: 17px;
        }
      }
    }
  }
  .paymentButton:not(.blocked) {
    display: none;
  }
  .redirection {
    .redirectHeader {
      font-size: 40px;
    }
    .redirectBody {
      font-size: 15px;
    }
  }
  .successDetails {
    flex-basis: 100% !important;
  }

  .successContainer {
    display: contents;
    text-align: center;
  }
  .successHeader {
    font-size: 30px !important;
  }
  .confirm {
    font-size: 25px !important;
  }
  .mailPrompt {
    font-size: 20px !important;
  }
  .limited {
    font-size: 30px !important;
    max-width: 350px !important;
  }
  .successSummary {
    margin-top: 40px;
    flex-basis: 100% !important;
    div:first-child {
      max-width: unset !important;
    }
  }
  .reason {
    font-size: 20px !important;
  }
  .failButton {
    font-size: 25px !important;
    width: 220px;
    height: 70px;
  }
  .paymentWrapper {
    max-width: 100%;
    .paymentButton {
      button {
        min-width: unset !important;
      }
    }
  }
}
