.main {
  min-width: 190px;
  height: 50px;
  position: relative;
}

.padded {
  padding: 10px;
}
.legend {
  margin-bottom: 15px;
  div {
    display: flex;
    align-items: center;
    color: var(--textColor);
    font-size: 15px;
    margin-top: 10px;
  }
}
.availableIcon {
  background-color: var(--enabledTile);
}
.unavailableIcon {
  background-color: var(--disabledTile);
}
.selectedIcon {
  background-color: var(--selectedTile);
}

.legendIcon {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 15px;
}

.chevron {
  rotate: 0deg;
  transition: all 0.3s;
}
.chevronDown {
  rotate: -180deg !important;
}

.selectHeader {
  height: 100%;
  width: 100%;
  padding: 10px 5px;
  border: none;
  cursor: pointer;
  display: flex;
  background: none;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  color: var(--textColor);
  display: flex;
  //justify-content: space-between;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
  span {
    overflow-wrap: anywhere;
  }
}
.selectHeader:disabled {
  * {
    color: rgb(184, 184, 184);
    fill: rgb(184, 184, 184);
  }
}
.selectHeader:not(:disabled):hover {
  outline: 1px solid rgba(15, 106, 160, 0.4);
}

.headerButton {
  display: flex;
  align-items: center;
  padding: 10px 5px;
  margin-right: 10px;

  span {
    margin-left: 10px;
  }
}
.optionsWrapper {
  background-color: white;
  position: absolute;
  //min-width: 680px;
  top: calc(100% + 10px);
  left: 0;
  border: 1px solid;
  border-color: rgba(15, 106, 160, 0.4);
  border-radius: 15px;
  transition: opacity 0.3s;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
  color: var(--textColor);
  font-size: 18px;
  padding: 0 10px 10px 10px;
}

.sectionWrapper {
  display: flex;
}

.section {
  padding: 10px 20px 20px 20px;
  min-width: 200px;
  border-right: 1px solid #dddddd;
}
.section:last-child {
  border: none;
}
.open {
  opacity: 1;
  pointer-events: visible !important;
}

.personHeader {
  font-size: 20px;
  font-weight: bold;
}
.personHeaderCost {
  color: #247eb1;
  font-size: 15px;
  margin-top: 20px;
}

.personSelector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 15px;
}

.amountWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75px;
  .amount {
    min-width: 25px;
    text-align: center;
    color: var(--selectedTile);
    font-weight: bold;
  }
  button {
    svg {
      vertical-align: text-bottom;
    }

    background: none;
    border: none;
    cursor: pointer;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  button {
    background-color: #136fa3;
    border: none;
    border-radius: 5px;
    color: #fff;
    width: 314px;
    height: 44px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    &:disabled {
      background-color: rgb(184, 184, 184);
      cursor: default;
    }
  }
}

@media screen and (max-width: 1100px) {
  .main {
    min-width: unset;
    //flex-basis: 50%;
    max-width: calc(50% - 5px);
    margin-top: 10px;
  }
  .sectionWrapper {
    flex-direction: column;
  }
  .selectHeader {
    padding: 5px !important;
    font-size: 14px !important;
  }
  .headerButton {
    padding: 0 !important;
    span {
      display: none;
    }
  }
  .optionsWrapper {
    top: unset !important;
    bottom: 100%;
    max-width: 240px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-height: 85vh;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  .chevron {
    rotate: -180deg;
  }
  .chevronDown {
    rotate: 0deg !important;
  }
  .section {
    border-right: none;
    border-bottom: 1px solid #dddddd;
    min-width: 234px;
  }
  .footer {
    button {
      width: 180px;
    }
  }
}
