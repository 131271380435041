.busy {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  border-radius: 50%;
  border-bottom: 5px solid rgb(255, 255, 255);
  animation-duration: 0.4s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.big {
  width: 55px !important;
  height: 55px !important;
}

@keyframes slidein {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
