.main {
  margin-bottom: 25px;
}

.header {
  color: var(--textColor);
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    margin-left: 10px;
  }
}

.inputsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.inputsWrapperHolder {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.inputsWrapperSmall {
  flex-wrap: nowrap;
  min-width: 340px;
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
}

.inputWrapper {
  width: 160px;
}
.inputMain {
  width: 100%;
  border: 1px solid rgba(15, 106, 160, 0.4);
  border-radius: 5px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  height: 50px;
  outline: none;
  color: rgba(15, 106, 160, 0.8);
  &::placeholder {
    color: rgba(15, 106, 160, 0.8);
    opacity: 1;
  }
}

.errored {
  border: 1px solid red;
  &::placeholder {
    color: red;
  }
}

.small {
  width: 120px !important;
}

.big {
  width: 340px !important;
}

.medium {
  min-width: 200px !important;
  flex-grow: 1;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

@media screen and (max-width: 600px) {
  .inputWrapper {
    width: 100%;
  }
  .small {
    width: 100% !important;
  }
  .big {
    width: 100% !important;
  }
  .inputsWrapperSmall {
    width: 100%;
  }
}
