.main {
  width: 200px;

  position: relative;
}
.selectHeader {
  cursor: pointer;

  height: 50px;
  width: 100%;
  border: 1px solid;
  border-color: rgba(15, 106, 160, 0.4);
  border-radius: 5px;

  display: flex;
  background: none;
  font-size: 12px;
  font-weight: bold;
  color: rgba(15, 106, 160, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
}
.top {
  min-width: 150px !important;
  .selectHeader {
    border: none !important;
    color: var(--textColor) !important;
  }
  .selectHeader:not(.big) {
    font-size: 15px !important;
  }
  .headerButton {
    span {
      margin-right: 7px;
    }
  }
  .optionsWrapper {
    min-width: max(200px, 100%);
    // max-width: 300px;
  }
}
.big {
  font-size: 17px !important;
}
.selectHeader:disabled {
  cursor: default;

  * {
    color: rgb(184, 184, 184);
    fill: rgb(184, 184, 184);
  }
}
.selectHeader:not(:disabled):not(.errored):hover {
  outline: 1px solid rgba(15, 106, 160, 0.4);
}
.selectHeader:not(:disabled).errored:hover {
  outline: 1px solid red;
}
.selectHeader.errored {
  border-color: red;
  * {
    color: red;
    fill: red;
  }
}

.headerButton {
  padding: 0 5px;
}

.headerClose {
  display: none;
  text-align: right;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.padded {
  padding: 10px !important;
}
.legend {
  margin-bottom: 15px;
  div {
    display: flex;
    align-items: center;
    color: var(--textColor);
    font-size: 15px;
    margin-top: 10px;
  }
}
.availableIcon {
  background-color: var(--enabledTile);
}
.unavailableIcon {
  background-color: var(--disabledTile);
}
.selectedIcon {
  background-color: var(--selectedTile);
}

.legendIcon {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 15px;
}

.chevron {
  rotate: 0deg;
  transition: all 0.3s;
}
.chevronDown {
  rotate: -180deg !important;
}

.optionsWrapper {
  background-color: white;
  position: absolute;
  min-width: 100%;
  top: calc(100% + 10px);
  left: 0;
  border: 1px solid;
  border-color: rgba(15, 106, 160, 0.4);
  border-radius: 5px;
  transition: opacity 0.3s;
  opacity: 0;
  // overflow: hidden;
  pointer-events: none;
  z-index: 1;
  padding: 1px;
}

.category {
  padding: 5px 0;
}
.category:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}

.catHeader {
  font-size: 15;
  color: #242424;
  padding-left: 5px;
}

.option {
  cursor: pointer;
  padding: 5px 0 5px 20px;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  color: #484e5d;
  font-size: 15px;
  background-size: 50%;
  background-repeat: no-repeat;
  transition: background-size 0.3s;
  background-color: transparent;
  &:hover {
    background-image: linear-gradient(#f5f7fa, #f5f7fa);
    background-size: 100%;
  }
}
.option:last-child {
  border: none !important;
}

.optionIcon {
  margin-right: 10px;
}

.open {
  opacity: 1;
  pointer-events: visible !important;
}
@media screen and (max-width: 600px) {
  .optionsWrapper.mobileFull {
    position: fixed !important;
    top: 0 !important;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    .headerClose {
      display: block !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .main {
    min-width: unset;
    //flex-basis: 50%;
    //max-width: calc(50% - 5px);
    // margin-top: 10px;
    width: 100%;
  }

  .selectHeader {
    padding: 5px !important;
    font-size: 14px !important;
  }
  .headerButton {
    padding: 0 !important;
  }
}
