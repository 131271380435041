.main {
  position: relative;
  // flex-basis: 50%;
  // max-width: calc(50% - 10px);
  margin-top: 10px;
  min-width: 240px;
}
.valueContainer {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.selectHeader {
  cursor: pointer;
  height: 50px;
  width: 100%;
  border: 1px solid;
  border-color: rgba(15, 106, 160, 0.4);
  border-radius: 5px;
  padding: 10px 5px;
  display: flex;
  background: none;
  font-size: 17px;
  font-weight: bold;
  color: rgba(19, 111, 163, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
  span {
    overflow-wrap: anywhere;
  }
}
.top {
  min-width: 150px !important;
  .selectHeader {
    border: none !important;
    color: var(--textColor) !important;
  }
  .selectHeader:not(.big) {
    font-size: 15px !important;
  }
  .headerButton {
    span {
      margin-right: 7px;
    }
  }
  .optionsWrapper {
    min-width: max(200px, 100%);
    // max-width: 300px;
  }
}
.big {
  font-size: 17px !important;
}
.selectHeader:disabled {
  cursor: default;

  * {
    color: rgb(184, 184, 184);
    fill: rgb(184, 184, 184);
  }
}
.selectHeader:not(:disabled):not(.errored):hover {
  outline: 1px solid rgba(15, 106, 160, 0.4);
}
.selectHeader:not(:disabled).errored:hover {
  outline: 1px solid red;
}
.selectHeader.errored {
  border-color: red;
  * {
    color: red;
    fill: red;
  }
}

.headerButton {
  display: flex;
  align-items: center;
  padding: 10px 5px;

  span {
    margin-left: 10px;
  }
}

.headerClose {
  display: none;
  text-align: right;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.padded {
  padding: 10px !important;
}
.legend {
  margin-bottom: 15px;
  div {
    display: flex;
    align-items: center;
    color: var(--textColor);
    font-size: 15px;
    margin-top: 10px;
  }
}
.availableIcon {
  background-color: var(--enabledTile);
}
.unavailableIcon {
  background-color: var(--disabledTile);
}
.selectedIcon {
  background-color: var(--selectedTile);
}

.legendIcon {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 15px;
}

.chevron {
  rotate: 0deg;
  transition: all 0.3s;
}
.chevronDown {
  rotate: -180deg !important;
}

.dateWrapper {
  max-height: unset !important;
}

.optionsWrapper {
  background-color: white;
  position: absolute;
  min-width: 100%;
  top: calc(100% + 10px);
  left: 0;
  border: 1px solid;
  border-color: rgba(15, 106, 160, 0.4);
  border-radius: 5px;
  transition: opacity 0.3s;
  opacity: 0;
  // overflow: hidden;
  pointer-events: none;
  z-index: 1001;
  padding: 1px;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: thin;
  min-height: 100px;
}

.infoIcon {
  margin-left: auto;
  position: relative;
  svg {
    vertical-align: bottom;
    fill: #bdbdbd;
  }
  &:hover {
    .pickupSummary {
      visibility: visible;
    }
  }
}

.option {
  padding: 10px 5px 10px 10px;
  border: none;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  color: var(--textColor);
  font-size: 15px;
  background-size: 50%;
  background-repeat: no-repeat;
  transition: background-size 0.3s;
  background-color: transparent;
  overflow-wrap: anywhere;
  &:hover {
    background-image: linear-gradient(#f5f7fa, #f5f7fa);
    background-size: 100%;
  }
}
.option:last-child {
  border: none !important;
}

.pickupSummary {
  border: 1px solid;
  border-color: rgba(15, 106, 160, 0.4);
  border-radius: 5px;
  padding: 10px;
  min-width: 180px;
  top: 100%;
  right: 0;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  visibility: hidden;
  text-align: left;
  font-size: 15px;

  div {
    margin-top: 3px;
    span:nth-child(2) {
      font-style: italic;
    }
  }
}
.optionIcon {
  margin-right: 10px;
}

.open {
  opacity: 1;
  pointer-events: visible !important;
}
@media screen and (max-width: 800px) {
  .main {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .optionsWrapper.mobileFull {
    position: fixed !important;
    top: 0 !important;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto;
    scrollbar-width: thin;

    .headerClose {
      display: block !important;
    }
  }
}
@media screen and (max-width: 1100px) {
  .selectHeader {
    padding: 5px !important;
    font-size: 14px !important;
  }
  .headerButton {
    padding: 0 !important;
  }
  .optionsWrapper.mobileFull {
    max-height: unset;
  }
  .top {
    min-width: unset !important;
    flex-basis: unset !important;
    .selectHeader {
      .headerButton {
        margin-right: 10px;
        span {
          display: none;
        }
      }
    }
    .optionsWrapper:not(.mobileFull) {
      top: unset !important;
      bottom: 100%;
      max-height: 60vh;
      overflow-y: auto;
      scrollbar-width: thin;
    }
    .chevron {
      rotate: -180deg;
    }
    .chevronDown {
      rotate: 0deg !important;
    }
    .optionsWrapper.mobileFull {
      position: fixed !important;
      top: 0 !important;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      overflow: auto;

      .headerClose {
        display: block !important;
      }
    }
  }
  .pickupSummary {
    bottom: 100%;
    top: unset !important;
  }
}
