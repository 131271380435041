.main {
  min-width: 190px;
  height: 50px;
  position: relative;
}

@media screen and (max-width: 800px) {
  .main:nth-child(even) {
    .optionsWrapper {
      right: calc(100% + 5px);
      left: unset;
      transform: translateX(50%);
    }
  }
  .main:nth-child(odd) {
    .optionsWrapper {
      left: calc(100% + 5px);
      transform: translateX(-50%);
    }
  }
}

.padded {
  padding: 10px;
}
.legend {
  margin-bottom: 15px;
  div {
    display: flex;
    align-items: center;
    color: var(--textColor);
    font-size: 15px;
    margin-top: 10px;
  }
}
.availableIcon {
  background-color: var(--enabledTile);
}
.unavailableIcon {
  background-color: var(--disabledTile);
}
.selectedIcon {
  background-color: var(--selectedTile);
}

.legendIcon {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 15px;
}

.chevron {
  rotate: 0deg;
  transition: all 0.3s;
}
.chevronDown {
  rotate: -180deg !important;
}

.selectHeader {
  height: 100%;
  width: 100%;
  padding: 10px 5px;
  border: none;
  cursor: pointer;
  display: flex;
  background: none;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
  span {
    overflow-wrap: anywhere;
  }
}
.selectHeader:disabled {
  * {
    color: rgb(184, 184, 184);
    fill: rgb(184, 184, 184);
  }
}
.selectHeader:not(:disabled):hover {
  outline: 1px solid #fff;
}

.headerButton {
  display: flex;
  align-items: center;
  padding: 10px 5px;
  margin-right: 10px;

  span {
    margin-left: 10px;
  }
}
.optionsWrapper {
  background-color: white;
  position: absolute;
  min-width: 340px;
  top: calc(100% + 10px);
  left: 0;
  border: 1px solid;
  border-color: rgba(15, 106, 160, 0.4);
  border-radius: 5px;
  transition: opacity 0.3s;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
  color: var(--textColor);
  padding: 20px;
  font-size: 18px;
}
.open {
  opacity: 1;
  pointer-events: visible !important;
}

.personHeader {
  text-align: center;
}

.personSelector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.amountWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  .amount {
    width: 40px;
    text-align: center;
  }
  button {
    svg {
      vertical-align: text-bottom;
    }

    background: none;
    border: none;
    cursor: pointer;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  button {
    background-color: #136fa3;
    border: none;
    border-radius: 5px;
    color: #fff;
    width: 210px;
    height: 44px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    &:disabled {
      background-color: rgb(184, 184, 184);
      cursor: default;
    }
  }
}

@media screen and (max-width: 1100px) {
  .main {
    min-width: unset;
    flex-basis: 50%;
    max-width: calc(50% - 5px);
    margin-top: 10px;
  }
  .selectHeader {
    padding: 5px !important;
    font-size: 14px !important;
  }
  .headerButton {
    padding: 0 !important;
  }
}
